<template>
  <div>
    <LastTerms />
  </div>
</template>

<script>
import store from '@/store';
import { termsStoreNames } from '@/store/terms';

export default {
  name: 'Terms',
  components: {
    async LastTerms() {
      if (store.getters[termsStoreNames.getStoreNames().getters.GET_TERMS_VERSION]['isDefault']) {
        await store.dispatch(termsStoreNames.getStoreNames().actions.FETCH_LAST_TERMS_VERSION, true);
      }
      const termsVersion = store.getters[termsStoreNames.getStoreNames().getters.GET_TERMS_VERSION]['term'];
      return import(`@/components/Terms/Terms-${termsVersion}`);
    },
  },
  data() {
    return {
      termsVersion: null,
    };
  },
};
</script>
